import React from "react";
import { Link, useNavigate } from "react-router-dom";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import {
  Box,
  Container,
  Divider,
  Typography,
  useMediaQuery,
} from "@mui/material";

import LockIcon from "@mui/icons-material/Lock";
import { theme } from "../../../App";
import { Motion } from "./Motion";
import {
  HIGHLIGHT_REMOVED,
  NOT_SELECTABLE,
  TEXT_NOT_SELECTABLE,
  baseBlue,
} from "../../graphic/theme";
import { withRouter } from "../../navigation/withRouter";
import LinkContainer from "./LinkContainer";

const Lock = () => {
  const editedIcon = React.cloneElement(<LockIcon />, {
    style: {
      height: "12px",
      maxWidth: "12px",
      fill: "white",
    },
  });
  return <>{editedIcon}</>;
};

function Footer() {
  const mobile = useMediaQuery(theme.breakpoints.only("xs"));
  let navigate = useNavigate();

  return (
    <footer>
      <Box
        sx={{
          width: "100%",
          height: `${180}px`,
          bgcolor: "black",
          position: "static",
          bottom: 0,
          //boxShadow: "0px -2px 5px 0px rgba(115,117,122,0.3)",
        }}
      >
        <Container maxWidth="lg">
          <Box
            sx={{
              width: "100%",
              height: "100%",
              p: "20px",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              <Box
                component={Link}
                sx={{
                  position: "relative",
                  display: "flex",
                  justifyContent: "center",
                  textDecoration: "none",
                  my: "10px",
                  pr: "10px",
                  "&:hover": {
                    cursor: "pointer",
                  },
                  ...HIGHLIGHT_REMOVED,
                }}
                onClick={() => {
                  navigate("/");
                }}
                to="/"
              >
                <Box
                  style={{
                    display: "flex",
                    height: "40px",
                    alignContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="logo"
                    sx={{
                      height: "100%",
                      height: "fit-content",
                      ...TEXT_NOT_SELECTABLE,
                    }}
                  >
                    Dopravabrno.cz
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ ml: "auto" }}>
                <Motion>
                  <Box
                    component="div"
                    sx={{
                      textDecoration: "none",
                      minWidth: "fit-content",
                      height: "22px",
                      bgcolor: baseBlue,
                      display: "flex",
                      flexDirection: "row",
                      alignContent: "center",
                      alignItems: "center",
                      borderRadius: "12px",
                      p: "4px",
                      cursor: "pointer",
                      WebkitTapHighlightColor: "transparent",
                    }}
                    onClick={() => {
                      navigate("/soukromi");
                    }}
                  >
                    <a
                      style={{
                        textDecoration: "none",
                        color: "white",
                        display: "flex",
                        flexDirection: "row",
                        alignContent: "center",
                        alignItems: "center",
                      }}
                      href="/soukromi"
                      onClick={(e) => {
                        e.preventDefault();
                      }}
                      aria-label="Zásady ochrany soukromí"
                    >
                      <Lock />
                      {!mobile && (
                        <Typography
                          sx={{
                            mx: "5px",
                            fontSize: "11px",
                            mt: "1px",
                            color: "white",
                            ...NOT_SELECTABLE,
                          }}
                        >
                          Zásady ochrany soukromí
                        </Typography>
                      )}
                    </a>
                  </Box>
                </Motion>
              </Box>
            </Box>
            <Box
              sx={{
                mt: "30px",
                width: "100%",
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  height: "100%",
                  //display: { xs: "none", sm: "flex", md: "flex" },
                }}
              >
                <Typography
                  variant="footerText"
                  sx={{
                    fontFamily: "Gordita",
                    fontWeight: "normal",
                    fontSize: "10px",
                    lineHeight: "15px",
                    textAlign: "left",
                    mr: "20px",
                  }}
                >
                  {mobile ? (
                    <>Ing. Petr Charvát</>
                  ) : (
                    <>
                      Služby zajišťuje Ing. Petr Charvát | IČO: 15564673 | DIČ:
                      CZ6502200309 | Sídlo: Högrova 2862/2 | 612 00 Brno,
                      Královo Pole
                    </>
                  )}
                </Typography>
              </Box>

              <Box
                sx={{
                  ml: "auto",
                  display: "flex",
                  height: "fit-content",
                  alignContent: "center",
                  alignItems: "center",
                }}
              >
                <LinkContainer
                  sx={{
                    height: "100%",
                    width: "100%",
                    display: "flex",
                    height: "fit-content",
                    alignContent: "center",
                    alignItems: "center",
                  }}
                  blank={false}
                  url={"tel:604110723"}
                >
                  <PhoneAndroidIcon
                    sx={{
                      fill: "white",
                      height: "17px",
                      width: "17px",
                      mr: "3px",
                    }}
                  />

                  <Typography
                    variant="footerText"
                    sx={{
                      my: "auto",
                      fontSize: "18px",
                      lineHeight: "19px",
                      whiteSpace: "nowrap",
                    }}
                  >
                    604 110 723
                  </Typography>
                </LinkContainer>
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>
    </footer>
  );
}

export default withRouter(Footer);
