import logo from "./logo.svg";
import "./App.css";
import {
  Box,
  CssBaseline,
  Fab,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import { Suspense } from "react";
import LoadingForced from "./resources/components/Base/LoadingForced";
import ScrollUpComp from "./resources/components/Base/ScrollUpComp";
import { Route, Routes } from "react-router-dom";
import routes from "./resources/navigation/routes";
import themeFile from "./resources/graphic/theme";
import Footer from "./resources/components/Base/Footer";
import Navbar from "./resources/components/Base/Navbar";

import FabPhone from "./resources/components/Base/FabPhone";
import { HelmetProvider } from "react-helmet-async";
import HelmetComponent from "./resources/components/Base/HelmetComponent";

export const theme = createTheme(themeFile());

function App() {
  return (
    <>
      <CssBaseline />
      <HelmetProvider>
        <div className="App">
          <Suspense fallback={<LoadingForced />}>
            <ScrollUpComp />
            <Routes>
              {routes.map((route, index) => (
                <Route
                  exact
                  path={route.path}
                  key={"RouteCore" + index}
                  element={
                    <ThemeProvider theme={theme}>
                      <HelmetComponent
                        title={route.title}
                        description={route.description}
                      />
                      <div style={{ minHeight: "100vh" }}>
                        <Navbar
                          notTransparent={route.notTransparent || false}
                        />
                        <div style={{}}>{route.element}</div>
                        <FabPhone />
                      </div>
                      <Footer />
                    </ThemeProvider>
                  }
                />
              ))}
            </Routes>
          </Suspense>
        </div>
      </HelmetProvider>
    </>
  );
}

export default App;
