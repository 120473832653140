import { Box, Fab } from "@mui/material";
import React from "react";
import { Motion } from "./Motion";
import { baseGrey, lightOrange } from "../../graphic/theme";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";

export default function FabPhone() {
  return (
    <div>
      <Box
        sx={{
          position: "fixed",
          right: 0,
          bottom: 0,
          display: { xs: "flex", sm: "flex", md: "none" },
          flexDirection: "column",
          mr: "10px",
          mb: "10px",
          zIndex: 1000,
        }}
      >
        <Motion>
          <Fab
            sx={{
              mb: "10px",
              bgcolor: lightOrange,
              "&:hover": { bgcolor: lightOrange },
              height: "70px",
              width: "70px",
            }}
          >
            <Box
              component="a"
              href={"tel:604110723"}
              sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                alignContent: "center",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <PhoneAndroidIcon
                sx={{ fill: baseGrey, height: "30px", width: "30px" }}
              />
            </Box>
          </Fab>
        </Motion>
      </Box>
    </div>
  );
}
