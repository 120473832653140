export const darkGrey = "#2E2E2E";
export const baseGrey = "#F2F1F1";
export const lightOrange = "#FE9E01";
export const brownOrange = "#E09A1F";
export const brown = "#874C3A";
export const yellow = "#FFD900";
export const textGrey = "#73757A";

export const baseBlue = "#0265F4";
export const darkBlue = "#001949";
export const baseRed = "#F54F64";
export const baseWhite = "#FBFBFB";

export const baseShadow3 = "1px 2px 5px 0px rgba(115,117,122,0.3)";
export const baseShadow36 = "1px 2px 6px 0px rgba(115,117,122,0.3)";

export const baseShadow2 = "1px 2px 5px 0px rgba(115,117,122,0.2)";
export const baseShadow5 = "1px 2px 5px 0px rgba(115,117,122,0.5)";
export const baseShadow8 = "1px 2px 5px 0px rgba(115,117,122,0.8)";

export const baseBorder = "1px solid #D9D9D9";
export const blueBorder = `2px solid ${baseBlue}`;
export const errorBorder = `2px solid ${baseRed}`;

export const borderRadius = "10px";

export const zindex = {
  guideOverlay: 9998,
  menuOverlay: 9999,
  navbar: 10000,
  overlay: 10001,
  maxOverlay: 10020,
};

export const SCREEN_SIZE = 24;

export const VERTICAL_SPACE = { xs: "10px", sm: "50px", md: "50px" };
export const NAVBAR_HEIGHT = 50;
export const FOOTER_HEIGHT = 180; // px

export const TEXT_NOT_SELECTABLE = {
  WebkitUserSelect: "none",
  WebkitTouchCallout: "none",
  msTouchSelect: "none",
  MozUserSelect: "none",
  msUserSelect: "none",
  userSelect: "none",
};

export const HIGHLIGHT_REMOVED = {
  WebkitTapHighlightColor: "transparent",
};

export const NOT_SELECTABLE = {
  ...TEXT_NOT_SELECTABLE,
  ...HIGHLIGHT_REMOVED,
};

export const tableScrollbarBottom = {
  borderBottomLeftRadius: { xs: "6px", sm: "12px" },
  borderBottomRightRadius: { xs: "6px", sm: "12px" },
  "&::-webkit-scrollbar": {
    display: { xs: "block", sm: "none" },
    height: "6px",
  },
  "&::-webkit-scrollbar-track": {
    display: { xs: "block", sm: "none" },
    boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
  },
  "&::-webkit-scrollbar-thumb": {
    display: { xs: "block", sm: "none" },
    backgroundColor: "rgba(0,0,0,0.3)",
    outline: "none",
    borderRadius: "16px",
    borderTopLeftRadius: { xs: "0px", sm: "12px" },
    borderTopRightRadius: { xs: "0px", sm: "12px" },
  },
};

const themeFile = () => {
  return {
    palette: {
      primary: {
        main: `${baseBlue}`,
        light: `${baseBlue}`,
        dark: `${darkBlue}`,
        contrastText: "#fff",
      },
      secondary: {
        light: "#ff6333",
        main: "#ff3d00",
        dark: "#b22a00",
        contrastText: "#fff",
      },
      textField: {
        light: lightOrange,
        main: lightOrange,
        dark: brownOrange,
        contrastText: "#000",
      },
      error: {
        light: baseRed,
        main: baseRed,
        dark: baseRed,
      },
    },
    components: {
      MuiTypography: {
        defaultProps: {
          variantMapping: {
            logo: "p",
          },
        },
        styleOverrides: {
          root: ({ ownerState }) => ({
            textTransform: "none",
            ...(ownerState.variant === "footerText" && {
              color: "white",
              fontFamily: "Rawline",
              fontWeight: "bold",
              fontSize: "14px",
              lineHeight: "15px",
            }),
            ...(ownerState.variant === "logo" && {
              color: "white",
              fontFamily: "Rawline",
              fontWeight: "800",
              fontSize: "16px",
              lineHeight: "17px",
              textTransform: "uppercase",
            }),
            ...(ownerState.variant === "navButton" && {
              color: darkGrey,
              fontFamily: "Rawline",
              fontWeight: "bold",
              fontSize: "16px",
              lineHeight: "17px",
              textTransform: "uppercase",
            }),
            ...(ownerState.variant === "navHeader" && {
              fontFamily: "Gordita",
              fontSize: "14px",
              lineHeight: "14px",
              fontWeight: "bold",
            }),
            ...(ownerState.variant === "buttonText" && {
              color: darkGrey,
              fontFamily: "Gordita",
              fontWeight: "bold",
              fontSize: "16px",
              lineHeight: "17px",
              textTransform: "none",
            }),
            ...(ownerState.variant === "body1" && {
              color: darkGrey,
              fontSize: "13px",
              fontWeight: "700",
            }),
            /* ******** ARTICLE TEXTS ******** */
            ...(ownerState.variant === "mainTitle" && {
              fontFamily: "Gordita",
              fontWeight: "bold",
              color: darkGrey,
              fontSize: { xs: "26px", sm: "26px" },
              lineHeight: { xs: "32px", sm: "34px" },
              textAlign: "left",
            }),
            ...(ownerState.variant === "mainText" && {
              fontFamily: "Gordita",
              color: textGrey,
              fontSize: "14px",
              lineHeight: "22px",
              textAlign: "left",
            }),
            ...(ownerState.variant === "buttonTextArticle" && {
              color: lightOrange,
              fontSize: "12px",
              lineHeight: "14px",
              fontWeight: "bold",
            }),
            ...(ownerState.variant === "smallText" && {
              fontFamily: "Gordita",
              fontWeight: "bold",
              color: brownOrange,
              textAlign: "left",
              display: "flex",
              alignContent: "center",
              fontSize: { xs: "15px", sm: "16px" },
              lineHeight: { xs: "16px", sm: "17px" },
              //alignSelf: "center",
            }),
            /* ******** TRUSTED PARTNER ******** */
            ...(ownerState.variant === "partnerTitle" && {
              fontFamily: "Gordita",
              fontWeight: "bold",
              fontSize: "26px",
              lineHeight: "34px",
            }),
            ...(ownerState.variant === "partnerText" && {
              fontFamily: "Gordita",
              color: textGrey,
              fontSize: "14px",
              lineHeight: "22px",
            }),
            /* ******** FORM TITLE TEXT ******** */
            ...(ownerState.variant === "formTitle" && {
              fontFamily: "Gordita",
              fontSize: "26px",
              fontWeight: "bold",
              color: darkGrey,
            }),
            /* ******** TABLE TEXTS ******** */
            ...(ownerState.variant === "tableTitle" && {
              fontFamily: "Gordita",
              fontSize: "13px",
              fontWeight: "bold",
              color: darkGrey,
            }),
            ...(ownerState.variant === "tableText" && {
              //fontFamily: "Gordita",
              fontSize: "14px",
              lineHeight: "15px",
              color: darkGrey,
            }),
          }),
        },
      },
      MuiButtonBase: {
        defaultProps: {
          disableRipple: true,
          //disableFocusRipple: true,
        },
      },
      MuiButton: {
        styleOverrides: {
          root: ({ ownerState }) => ({}),
        },
      },
      MuiGrid: {
        styleOverrides: {
          root: ({ ownerState }) => ({
            padding: 0,
          }),
        },
      },
    },
    typography: {
      useNextVariants: true,
    },
  };
};
export default themeFile;
