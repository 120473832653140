import React from "react";

import { Box, Typography, Container } from "@mui/material";
import { brownOrange, darkGrey, lightOrange } from "../../graphic/theme";

export default function Kontakt() {
  const textStyle = {
    color: darkGrey,
    fontFamily: "Gordita",
    fontWeight: "normal",
  };

  return (
    <div style={{ backgroundColor: "white" }}>
      <Container maxWidth="md" sx={{ minHeight: "100vh", pt: "50px" }}>
        <Box sx={{ mt: "50px" }}>
          <Typography
            sx={{
              ...textStyle,
              textAlign: "center",
              fontSize: "26px",
              fontWeight: "bold",
              fontFamily: "Gordita",
            }}
          >
            <br />
            Kontakt
            <br />
            <br />
          </Typography>
        </Box>
        <Box sx={{}}>
          <Typography
            sx={{
              textAlign: "center",
              fontSize: "14px",
              fontFamily: "Gordita",
            }}
          >
            <br />
            Ing. Petr Charvát
            <br />
            <br />{" "}
            <span style={{ fontWeight: "normal" }}>
              Högrova 2862/2, Královo Pole <br />
              612 00 Brno
            </span>
            <br />
            <br />
            IČO: <span style={{ fontWeight: "normal" }}>15564673</span> <br />
            DIČ: <span style={{ fontWeight: "normal" }}>CZ6502200309</span>
            <br />
            <br />
            <b>Email:</b> <br />
            <a
              style={{ textDecoration: "none", ...textStyle }}
              href="mailto: bagrbrno@email.cz"
            >
              bagrbrno@email.cz
            </a>
            <br />
            <br />
            Telefon:
            <br />
            <a
              style={{ textDecoration: "none", ...textStyle }}
              href="tel: 797996873"
            >
              797996873
            </a>{" "}
            <br />
            <a
              style={{ textDecoration: "none", ...textStyle }}
              href="tel: 604110723"
            >
              604110723
            </a>
            <br />
            <br />
            <a
              style={{
                textDecoration: "none",
                ...textStyle,
                fontWeight: "bold",
              }}
              href="/soukromi"
              aria-label="Zásady ochrany soukromí"
            >
              Zásady ochrany soukromí
            </a>
          </Typography>
        </Box>
      </Container>
    </div>
  );
}
