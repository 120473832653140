import React, { lazy } from "react";
import Kontakt from "../pages/Legal/Kontakt";
import OchranaSoukromi from "../pages/Legal/OchranaSoukromi";

const DopravaBrno = lazy(() => import("../pages/DopravaBrno"));
const CenikPage = lazy(() => import("../pages/Ceniky/CenikPage"));
const AutaCenik = lazy(() => import("../pages/Ceniky/AutaCenik"));
const KontejneryCenik = lazy(() => import("../pages/Ceniky/KontejneryCenik"));

let baseRoutes = [
  {
    path: "/",
    element: <DopravaBrno />,
    title:
      "Doprava stavebních materiálů | Kontejnery na stavební odpad | Ukládka zeminy a stavebních odpadů",
    description:
      "Zajišťujeme dopravu stavebních materiálů v širokém okolí Brna. Přepravujeme materiály velkých i malých objemů. Spolehlivost je u nás na prvním místě. Odpovědná osoba: Ing. Petr Charvát.",
  },
  {
    path: "/doprava",
    element: <AutaCenik />,
    notTransparent: true,
    title:
      "Doprava stavebních materiálů a ukládka stavebního odpadu | Brno a okolí |  Vysokotonážní i nízkotonážní přeprava",
    description:
      "Zajišťujeme přepravu stavebního materiálu po Brně a jeho širokém okolí. Přepravujeme materiály velkých i malých objemů. Spolehlivost je u nás na prvním místě. Odpovědná osoba: Ing. Petr Charvát.",
  },
  {
    path: "/kontejnery",
    element: <KontejneryCenik />,
    notTransparent: true,
    title:
      "Kontejnery na likvidaci stavebního odpadu | Velké i malé objemy | Brno a okolí | www.dopravabrno.cz",
    description:
      "Zajišťujeme přistavení stavebních kontejnerů a likvidaci stavebního odpadu v Brně a jeho širokém okolí. Spolehlivost je u nás na prvním místě. Odpovědná osoba: Ing. Petr Charvát.",
  },
  {
    path: "/cenik",
    element: <CenikPage />,
    notTransparent: true,
    title: "Ceník | Přeprava materiálu | Stavební kontejnery",
    description:
      "Hodinové sazby, smluvní ceny, individuální nacenění. Odpovědná osoba: Ing. Petr Charvát; IČO: 15564673; DIČ: CZ6502200309; Sídlo: Högrova 2862/2; 612 00 Brno, Královo Pole.",
  },
  {
    path: "/kontakt",
    element: <Kontakt />,
    notTransparent: true,
    title: "Kontakt | www.dopravabrno.cz",
    description:
      "Odpovědná osoba: Ing. Petr Charvát; IČO: 15564673; DIČ: CZ6502200309; Sídlo: Högrova 2862/2; 612 00 Brno, Královo Pole. ",
  },
  {
    path: "/soukromi",
    element: <OchranaSoukromi />,
    notTransparent: true,
    title: "Ochrana soukromí | www.dopravabrno.cz",
    description:
      "Odpovědná osoba: Ing. Petr Charvát; IČO: 15564673; DIČ: CZ6502200309; Sídlo: Högrova 2862/2; 612 00 Brno, Královo Pole. ",
  },
];

const routes = [
  ...baseRoutes,
  {
    path: "*",
    element: <DopravaBrno />,
    title:
      "Doprava stavebních materiálů | Kontejnery na stavební odpad | Ukládka zeminy a stavebních odpadů",
    description:
      "Zajišťujeme dopravu stavebních materiálů v širokém okolí Brna. Přepravujeme materiály velkých i malých objemů. Spolehlivost je u nás na prvním místě. Odpovědná osoba: Ing. Petr Charvát.",
  },
];
export default routes;
