import { Box, Container, Typography } from "@mui/material";
import React from "react";

export default function OchranaSoukromi() {
  return (
    <>
      <Container maxWidth="md">
        <Box sx={{ pt: "80px" }}>
          <Typography
            sx={{
              textAlign: "center",
              fontSize: "14px",
              fontWeight: "bold",
              pb: "20px",
            }}
          >
            <br />
            <br />
            INFORMACE O ZPRACOVÁNÍ OSOBNÍCH ÚDAJŮ
            <br />
          </Typography>
          <Typography
            sx={{
              textAlign: "left",
              fontSize: "14px",
              fontWeight: "normal",
              pb: "100px",
            }}
          >
            ÚVOD:
            <br />
            <br />
            Zpracováváme osobní údaje za účelem plnění smlouvy s Vámi (nebo pro
            provedení opatření přijatých před uzavřením takové smlouvy), dále
            provádí zpracování Vašich osobních údajů, které je nezbytné pro
            plnění veřejnoprávních povinností této společnosti.
            <br />
            <br />
            TOTOŽNOST A KONTAKTNÍ ÚDAJE SPRÁVCŮ
            <br />
            <br />
            1. Správcem Vašich osobních údajů je Ing. Petr Charvát; IČO:
            15564673; DIČ: CZ6502200309, se sídlem Högrova 2862/2, Královo Pole,
            612 00 Brno (dále jen jako „správce“).
            <br />
            2. Kontaktní údaje správce jsou následující: adresa pro doručování
            Ing. Petr Charvát, Högrova 2862/2, Královo Pole, 612 00 Brno, adresa
            elektronické pošty bagrbrno@email.cz , telefon +420 797996873.
            <br />
            3. Správce nejmenoval pověřence pro ochranu osobních údajů.
            <br />
            <br />
            PRÁVNÍ ZÁKLAD ZPRACOVÁNÍ OSOBNÍCH ÚDAJŮ
            <br />
            <br />
            1. Právním základem zpracování Vašich osobních údajů je skutečnost,
            že toto zpracování je nezbytné pro:
            <br />
            2. splnění smlouvy mezi Vámi a správcem nebo pro provedení opatření
            správcem před uzavřením takové smlouvy ve smyslu čl. 6 odst. 1 písm.
            b) Nařízení Evropského parlamentu a Rady 2016/679 o ochraně
            fyzických osob v souvislosti se zpracováním osobních údajů a o
            volném pohybu těchto údajů a o zrušení směrnice 95/46/ES (obecné
            nařízení o ochraně osobních údajů) (dále jen „nařízení“);
            <br />
            3. splnění právních povinností, které se na správce vztahují,
            ve smyslu čl. 6 odst. 1 písm. c) nařízení, a to konkrétně zejména
            splnění povinností uložených správci obecně závaznými právními
            předpisy, zejména zákonem č. 235/2004 Sb., o dani z přidané hodnoty,
            ve znění pozdějších předpisů, zákonem č. 586/1992 Sb., o daních
            z příjmů, ve znění pozdějších předpisů, a zákonem č. 563/1991 Sb., o
            účetnictví, ve znění pozdějších předpisů.
            <br />
            <br />
            ÚČEL ZPRACOVÁNÍ OSOBNÍCH ÚDAJŮ
            <br />
            <br />
            1. Účelem zpracování Vašich osobních údajů je plnění smlouvy mezi
            Vámi a správcem či provedení opatření správcem před uzavřením takové
            smlouvy a dále plnění souvisejících veřejnoprávních povinností
            správcem.
            <br />
            2. Ze strany správce nedochází k automatickému individuálnímu
            rozhodování ve smyslu čl. 22 nařízení.
            <br />
            <br />
            DOBA ULOŽENÍ OSOBNÍCH ÚDAJŮ
            <br />
            <br />
            1. Vaše osobní údaje budou zpracovávány po dobu trvání účinků práv a
            povinností ze smlouvy, a dále po dobu nutnou pro účely archivování
            podle příslušných obecně závazných právních předpisů, nejdéle však
            po dobu stanovenou obecně závaznými právními předpisy.
            <br />
            <br />
            DALŠÍ PŘÍJEMCI OSOBNÍCH ÚDAJŮ
            <br />
            <br />
            1. Dalšími příjemci Vašich osobních údajů budou osoby zajišťující
            pro správce v souladu s pokyny správce (zpracovatelé osobních údajů)
            technické a organizační služby, včetně provozu software a ukládání
            dat, osoby zajišťující pro správce daňové a účetní služby.
            <br />
            2. Příjemci Vašich osobních údajů pro plnění povinností
            vyplývajících z právních předpisů můžou být dále orgány finanční
            správy či jiné příslušné úřady v případech, kdy tak správci ukládají
            obecně závazné právní předpisy.
            <br />
            3. Správce nemá v úmyslu předat Vaše osobní údaje do třetí země (do
            země mimo EU) nebo mezinárodní organizaci.
            <br />
            <br />
            PRÁVA SUBJEKTU ÚDAJŮ
            <br />
            <br />
            1. Za podmínek stanovených v nařízení máte právo požadovat od
            správce přístup k Vašim osobním údajům, právo na opravu nebo výmaz
            Vašich osobních údajů, popřípadě omezení jejich zpracování a dále
            právo na přenositelnost Vašich osobních údajů.
            <br />
            2. Pokud byste se domníval(a), že zpracováním Vašich osobních údajů
            bylo porušeno či je porušováno nařízení, máte mimo jiné právo podat
            stížnost u dozorového úřadu.
            <br />
            3. Nemáte povinnost osobní údaje poskytnout. Poskytnutí Vašich
            osobních údajů je nutným požadavkem pro uzavření a plnění smlouvy a
            bez poskytnutí Vašich osobních údajů není možné smlouvu uzavřít
            či ji ze strany správce splnit.
          </Typography>
        </Box>
      </Container>
    </>
  );
}
